// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/utils/useActiveOrder.tsx"
);
import.meta.hot.lastModified = "1721073401809.348";
}
// REMIX HMR END

import { useFetcher } from '@remix-run/react'
import { CartLoaderData } from '~storefront/routes/api.active-order'
import { useEffect, useMemo } from 'react'
import { OrderLineCustomFieldsInput } from '~storefront/generated/graphql'

export function useActiveOrder() {
  const activeOrderFetcher = useFetcher<CartLoaderData>()

  useEffect(() => {
    // TODO this only works with js enabled
    if (
      activeOrderFetcher.state === 'idle' &&
      activeOrderFetcher.data == null
    ) {
      activeOrderFetcher.load('/api/active-order')
    }
  }, [activeOrderFetcher])

  const { activeOrder } = useMemo(
    () => activeOrderFetcher.data ?? {},
    [activeOrderFetcher.data],
  )

  function refresh() {
    activeOrderFetcher.load('/api/active-order')
  }

  const removeItem = (lineId: string) => {
    activeOrderFetcher.submit(
      {
        action: 'removeItem',
        lineId,
      },
      {
        method: 'post',
        action: '/api/active-order',
      },
    )
  }

  const applyCoupon = (couponCode: string) => {
    activeOrderFetcher.submit(
      {
        action: 'applyCoupon',
        couponCode,
      },
      {
        method: 'post',
        action: '/api/active-order',
      },
    )
  }
  const removeCoupon = (couponCode: string) => {
    activeOrderFetcher.submit(
      {
        action: 'removeCoupon',
        couponCode,
      },
      {
        method: 'post',
        action: '/api/active-order',
      },
    )
  }

  const adjustOrderLine = (
    lineId: string,
    quantity: number,
    customFields: OrderLineCustomFieldsInput = {},
  ) => {
    activeOrderFetcher.submit(
      {
        action: 'adjustItem',
        lineId,
        quantity: quantity.toString(),
        customFields: JSON.stringify(customFields),
      },
      {
        method: 'post',
        action: '/api/active-order',
      },
    )
  }

  return {
    activeOrderFetcher,
    activeOrder,
    removeItem,
    adjustOrderLine,
    applyCoupon,
    removeCoupon,
    refresh,
  }
}
